/**
 * @constant OBSERVER_CONFIG
 * @description Constant to store configuration for mutation observers.
 * @type {{
 *     childList: boolean,
 *     subtree: boolean,
 * }}
 * @property {boolean} childList Set to true to observe mutations to target's attributes.
 * @property {boolean} subtree Set to true to observe mutations to not just target,
 * but also target's descendants.
 */
const OBSERVER_CONFIG = { childList: true, subtree: true };

/**
 * @method onReady
 * @description Creates a callback to be applied when the DOM has completed loading the content
 * @param callback {Function} Method to apply when the DOM has loaded the page content
 */
function onReady(callback) {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callback);
    } else {
        callback();
    }
}

/**
 * @method onElementReady
 * @description Creates a callback to be applied when the element specified in nodeName has been
 * added to the DOM
 * under targetNode.
 * @param selector {string} Element selector that we are checking has been added to DOM.
 * @param targetNode {string} Element name for node whose mutations we are observing.
 * @param callback {Function} Method to apply when the element has been added to the DOM.
 * @param observerConfig {Object} Configuration settings for mutation observer, defaults
 * to constant defined above.
 * @param disconnect {boolean} Determines whether we should disconnect the observer once
 * the element has been added,
 * defaults to true.
 */
function onElementReady(selector, targetNode, callback, observerConfig = OBSERVER_CONFIG,
    disconnect = true) {
    const observer = new MutationObserver((mutationsList, obs) => {
        mutationsList.forEach((mutation) => {
            if (mutation.addedNodes[0] && mutation.addedNodes[0].matches(selector)) {
                callback();
                if (disconnect) {
                    obs.disconnect();
                }
            }
        });
    });

    observer.observe(targetNode, observerConfig);
}

/**
 * @module domState
 * @description
 * Module for performing tasks based on the state of the DOM
 */
export default {
    onReady,
    onElementReady
};
